import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

const ApplicationBreadcrumbs = ({ applicationName }) => {
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  return (
    <Breadcrumbs
      separator={
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_next.png`}
          alt="dashboardIcon"
          className="w-[18px] h-[18px]"
        />
      }
      aria-label="breadcrumb"
    >
      <Link
        underline="hover"
        key="1"
        color="inherit"
        component={RouterLink}
        to="/"
      >
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_dashboard.png`}
          alt="dashboardIcon"
          className="w-6 h-6"
        />
      </Link>
      <Link
        underline="hover"
        key="2"
        color="inherit"
        component={RouterLink}
        to="/global-settings"
      >
        Global Settings
      </Link>
      <Typography key="3" color="text.primary">
        {applicationName}
      </Typography>
    </Breadcrumbs>
  );
};

export default ApplicationBreadcrumbs;
