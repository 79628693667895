import React from "react";
import { Box, Card } from "@mui/material";

const TransactionCountBox = (props) => {
  const { label, count, icon, onClick } = props;
  return (
    <Card
      variant="outlined"
      className="!rounded-lg py-6 px-4 hover:border-2 cursor-pointer"
      onClick = {onClick}
    >
      <Box className="flex justify-between">
        <span className="rounded-full bg-[#F4F4F5]">{icon}</span>
        <Box className="flex flex-col items-end">
          <p className="text-2xl font-medium">{count}</p>
          {label}
        </Box>
      </Box>
    </Card>
  );
};

export default TransactionCountBox;
