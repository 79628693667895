import { FormControl, MenuItem, Modal, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import CommonButton from "../common/CommonButton.js";
import apiRequest from "../../utils/api.js";
import { useParams } from "react-router-dom";
import Success from "./tabs/Success.js";
import { useSelector } from "react-redux";

const SendNotificationModal = (props) => {
  const [value, setValue] = useState({ select: "", message: "" });
  const [isSuccess, setIsSuccess] = useState(false);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const params = useParams();

  const openSuccessModal = () => {
    setIsSuccess(false);
  };

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const callNotificationTransactionApi = async () => {
    const body = {
      transactionId: params?.id,
      status: value?.select,
      message: value?.message,
    };
    const res = await apiRequest(
      "/send-webhook-notification-for-transaction",
      "POST",
      body
    );
    if (res.success) {
      setIsSuccess(!isSuccess);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await callNotificationTransactionApi();
  };
  return (
    <>
      <Modal open={props.open} className="flex justify-center items-center">
        <div className="flex  bg-zinc-100 p-4 px-8 h-max rounded-xl send-Notification">
          <form className="flex flex-col gap-10" onSubmit={handleFormSubmit}>
            <div className="flex justify-between">
              <div>
                <p className="text-2xl text-zinc-900 font-semibold">
                  Send Notification
                </p>
                <small className="text-zinc-600">
                  Send notification about the transaction
                </small>
              </div>
              <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/close.png`}
                alt="Close"
                onClick={props.close}
                className="w-6 h-6 cursor-pointer hover:bg-gray-200"
              />
            </div>
            <div className="flex gap-4 items-center text-zinc-800 font-normal text-base">
              <p>Status:</p>
              <FormControl>
                <Select
                  name="select"
                  value={value?.select}
                  onChange={(e) => handleChange(e)}
                  className="w-80 bg-zinc-200 select-classes"
                  size="small"
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em className="text-zinc-600">Select Status</em>
                  </MenuItem>
                  <MenuItem value="SUCCESS">SUCCESS</MenuItem>
                  <MenuItem value="REJECTED">REJECTED</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-col">
              <p className="flex justify-start text-zinc-800 font-normal text-base">
                Note:
              </p>
              <TextField
                type="text"
                placeholder="Enter your note..."
                variant="outlined"
                value={value?.message}
                onChange={(e) => handleChange(e)}
                name="message"
                rows={6}
                fullWidth
                multiline
                className="bg-zinc-200 textarea-field rounded-lg"
              />
            </div>
            <div className="flex justify-end">
              <CommonButton
                type="submit"
                text="Send"
                bgColor="black"
                width="w-36"
                borderRadius="rounded-3xl"
              />
            </div>
          </form>
        </div>
      </Modal>
      {isSuccess && (
        <Success
          close={openSuccessModal}
          closeNotificationModal={props.close}
          status={value?.select}
        />
      )}
    </>
  );
};
export default SendNotificationModal;
