import { Checkbox, FormControl, ListItemText, MenuItem, Select, styled } from '@mui/material';
import * as React from 'react';
import apiRequest from '../../utils/api.js';
import { useDispatch } from 'react-redux';
import { setApplicationIds } from '../../redux/actions/globalSettingsAction.js';

const MultipleSelectDropDown = () => {
  const [applicationNames, setApplicationNames] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState([]);
  const [applicationIdsList, setApplicationIdsList] = React.useState([]);
  const [dropDownOptionsMenu, setDropDownOptionsMenu] = React.useState([]);
  const dispatch = useDispatch();

  const StyledMenuItem = styled(MenuItem)({
    padding: '0px',
  })

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("All Applications")) {
      setSelectedOption(dropDownOptionsMenu);
      handleAppApplicationIds(dropDownOptionsMenu);
    } else if (selectedOption.includes("All Applications")) {
      setSelectedOption([]);
      setApplicationIdsList([])
    } else {
      setSelectedOption(
        typeof value === "string" ? value.split(',') : value
      )
      handleAppApplicationIds(value);
    }
  };

  const handleAppApplicationIds = (options) => {
    let applicationIds = applicationNames.filter((appName) => {
      let filteredValue = options.includes(appName.name)
      return filteredValue;
    }).map((obj) => obj._id)
    setApplicationIdsList(applicationIds)
  }

  const handleMultiSelectApplication = async () => {
    const accessToken = localStorage.getItem("token");
    const res = await apiRequest("/list-application", "POST", {
      token: accessToken,
    });
    if (res.success) {
      let applicationList = res?.data?.applicationsList;
      const newOptions = applicationList?.map(({ _id, name }) => {
        return { _id, name };
      });
      setApplicationNames(newOptions);
      const dropdownNames = ["All Applications"];
      newOptions.forEach(({ name }) => {
        dropdownNames.push(name);
      });
      setDropDownOptionsMenu(dropdownNames);
    }
  };

  React.useEffect(() => {
    handleMultiSelectApplication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    dispatch(setApplicationIds(applicationIdsList))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationIdsList])

  return (
    <div className="dashboard">
      <FormControl>
        <Select
          multiple
          value={selectedOption}
          onChange={handleChange}
          renderValue={(selected) => selected.length > 0 ? selected.join(', ') : "All Applications"}
          size='small'
          className='multi-select'
          displayEmpty
          MenuProps={{
            PaperProps: {
              className: 'custom-paper',
            },
          }}
        >
          {dropDownOptionsMenu?.map((option, index) => {
            return (
              <StyledMenuItem key={index} value={option}>
                <Checkbox checked={selectedOption.includes(option)} />
                <ListItemText primary={option} />
              </StyledMenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  );
}
export default MultipleSelectDropDown

