import React, { useEffect } from "react";
import { Box, Button, MenuItem } from "@mui/material";
import apiRequest from "../../utils/api.js";
import FilterDropdown from "../common/FilterDropdown.js";
import { useSelector, useDispatch } from "react-redux";
import { setAllApplications } from "../../redux/actions/commonAction.js";
import constant from "../../utils/constant.js";

const CentralisedWalletListFilters = (props) => {
  const {
    setFilters,
    setPage,
    handleDrawer,
    filterInputFields,
    setFilterInputFields,
    resetFilters,
    isDrawerOpen,
  } = props;

  const dispatch = useDispatch();
  const allApplications = useSelector(
    (state) => state?.common?.allApplications
  );
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  useEffect(() => {
    const getApplications = async () => {
      if (isDrawerOpen && allApplications?.length === 0) {
        const res = await apiRequest("/get-all-application", "POST");
        if (res?.success) {
          dispatch(setAllApplications(res.data));
        }
      }
    };
    getApplications();
  }, [isDrawerOpen, dispatch, allApplications]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterInputFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    setPage(1);
    setFilters(filterInputFields);
    handleDrawer(false);
  };

  const walletBlockchain = constant?.walletBlockchain;
  const walletCrypto = constant?.walletCrypto;

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex gap-4">
        <FilterDropdown
          label="application"
          value={filterInputFields?.applicationId || "Select an option"}
          name="applicationId"
          data={allApplications?.allApplications}
          handleInputChange={handleInputChange}
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {allApplications?.allApplications?.length > 0 &&
            allApplications?.allApplications.map((item, index) => {
              return (
                <MenuItem
                  key={`${item?._id}${index}`}
                  value={item?.applicationId}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="blockchain"
          value={filterInputFields.blockchain || "Select an option"}
          name="blockchain"
          data={walletBlockchain}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder={"Blockchain"}
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {walletBlockchain?.length > 0 &&
            walletBlockchain.map((item, index) => {
              return (
                <MenuItem key={`${item?.blockchain}${index}`} value={item?.key}>
                  {item?.blockchain}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="crypto"
          value={filterInputFields?.crypto || "Select an option"}
          name="crypto"
          data={walletCrypto}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder={"Crypto"}
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {walletCrypto?.length > 0 &&
            walletCrypto.map((item, index) => {
              return (
                <MenuItem key={`${item?.crypto}${index}`} value={item?.key}>
                  {item?.crypto}
                </MenuItem>
              );
            })}
        </FilterDropdown>
      </Box>
      <Box className="flex self-end gap-2">
        <Button
          size="large"
          variant="outlined"
          id="reset"
          onClick={resetFilters}
        >
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
            alt="reload-icon"
            className="w-6 h-6 mr-2"
          />
          Reset
        </Button>
        <Button size="large" variant="contained" id="search" onClick={onSubmit}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
            alt="search-icon"
            className="w-6 h-6 mr-2"
          />
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default CentralisedWalletListFilters;
