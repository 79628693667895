import React, { useState, useEffect } from "react";
import { Box, Button, MenuItem } from "@mui/material";
import apiRequest from "../../utils/api.js";
import FilterDropdown from "../common/FilterDropdown.js";
import { useSelector } from "react-redux";

const WalletListFilters = (props) => {
  const [inputFields, setInputFields] = useState({
    applicationId: "",
    blockchain: [],
    crypto: [],
  });
  const [allApplications, setAllApplications] = useState([]);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  useEffect(() => {
    const getApplications = async () => {
      const res = await apiRequest("/get-all-application", "POST");
      if (res?.success) {
        setAllApplications(res.data);
      }
    };
    getApplications();
  }, []);

  const { setFilters, setPage, handleDrawer } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "crypto" || name === "bitcoin") {
      setInputFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onSubmit = () => {
    setPage(1);
    setFilters(inputFields);
    handleDrawer(false);
  };

  const reset = () => {
    setFilters({});
    setInputFields({
      applicationId: "",
      blockchain: [],
      crypto: [],
    });
  };

  const walletBlockchain = [
    { blockchain: "Bitcoin", key: "Bitcoin" },
    { blockchain: "Ethereum", key: "Ethereum" },
    { blockchain: "Litecoin", key: "Litecoin" },
    { blockchain: "Ripple", key: "Ripple" },
    { blockchain: "Tron", key: "Tron" },
  ];
  const walletCrypto = [
    { crypto: "Ethereum", key: "ETH" },
    { crypto: "Litecoin", key: "LTC" },
    { crypto: "Ripple", key: "XRP" },
    { crypto: "Tron", key: "TRX" },
    { crypto: "DAI", key: "DAI" },
    { crypto: "USDT", key: "USDT" },
    { crypto: "USDC", key: "USDC" },
    { crypto: "Bitcoin", key: "BTC" },
  ];

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex gap-4">
        <FilterDropdown
          label="Application"
          value={inputFields.applicationId || "Select an option"}
          name="applicationId"
          data={allApplications}
          handleInputChange={handleInputChange}
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {allApplications?.length > 0 &&
            allApplications.map((item, index) => {
              return (
                <MenuItem
                  key={`${item?._id}${index}`}
                  value={item?.applicationId}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Blockchain"
          value={inputFields.blockchain || "Select an option"}
          name="blockchain"
          data={walletBlockchain}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder={"Blockchain"}
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {walletBlockchain?.length > 0 &&
            walletBlockchain.map((item, index) => {
              return (
                <MenuItem key={`${item?.blockchain}${index}`} value={item?.key}>
                  {item?.blockchain}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Crypto"
          value={inputFields.crypto || "Select an option"}
          name="crypto"
          data={walletCrypto}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder={"Crypto"}
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {walletCrypto?.length > 0 &&
            walletCrypto.map((item, index) => {
              return (
                <MenuItem key={`${item?.crypto}${index}`} value={item?.key}>
                  {item?.crypto}
                </MenuItem>
              );
            })}
        </FilterDropdown>
      </Box>
      <Box className="flex self-end gap-2">
        <Button size="large" variant="outlined" id="reset" onClick={reset}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
            alt="reload-icon"
            className="w-6 h-6 mr-2"
          />
          Reset
        </Button>
        <Button size="large" variant="contained" id="search" onClick={onSubmit}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
            alt="search-icon"
            className="w-6 h-6 mr-2"
          />
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default WalletListFilters;
