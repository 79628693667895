import React from "react";

const CommonButton = ({
  text,
  bgColor,
  width,
  EndIcon,
  StartIcon,
  onClick,
  disabled = false,
  type = "submit",
  size = "large",
  borderColor = "bg-zinc-100",
  borderRadius = "rounded-lg",
  background = "bg-transparent",
}) => {
  const cssClass =
    bgColor === "black"
      ? "bg-black hover:bg-neutral-400 text-sm h-10 font-medium text-white "
      : `${background} hover:bg-neutral-400 text-sm font-medium text-black border border-${borderColor}`;

  const sizeCSSClass = size === "large" ? "h-10" : "h-9";

  const disabledCSSClass =
    disabled === false ? "cursor-pointer" : "cursor-not-allowed bg-neutral-400";

  const handleClick = (event) => {
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      className={` px-4 py-2 flex justify-center items-center gap-2 ${size} ${borderRadius} ${cssClass} ${width} ${disabledCSSClass} ${sizeCSSClass}`}
      disabled={disabled}
    >
      {!!StartIcon && StartIcon} {text} {!!EndIcon && EndIcon}
    </button>
  );
};

export default CommonButton;
