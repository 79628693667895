import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import CustomTabPanel from "../../../common/CustomTabPanel.js";
import TransactionsTab from "./Transactions/TransactionsTab.js";
import GlobalCryptoTab from "./Crypto/GlobalCryptoTab.js";
import IpWhiteListing from "./IpWhiteListing/IpWhiteListing.js";
import Webhooks from "./Webhooks/Webhooks.js";
import APIKey from "./APIKey/APIKey.js";
import "../../css/tabs.css";

const ApplicationTabs = ({ tabValue, handleChange, params }) => {
  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });
  const tabLabels = ["Crypto", "Transactions", "Webhooks", "IP Whitelisting", "API Key"];

  return (
    <Box className="w-full overflow-y-scroll">
      <Tabs
        className="border-b-[2px] mt-4 pl-6 customTab"
        id="tabs"
        value={tabValue}
        onChange={handleChange}
      >
        {tabLabels.map((label, index) => {
          return (
            <Tab
              key={`ApplicationTab${index}`}
              label={label}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <GlobalCryptoTab />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <TransactionsTab applicationId={params?.id} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <Webhooks />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <IpWhiteListing />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
        <APIKey />
      </CustomTabPanel>
    </Box>
  );
};

export default ApplicationTabs;
