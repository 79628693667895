import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer.js";

const configureAppStore = () => {
  const initialState =
    window && process.env.NODE_ENV !== "development"
      ? {
        config: {
          apiUrl: window.INITIAL_STATE?.config?.DOMAIN,
          imageBaseUrl: window.INITIAL_STATE?.config?.IMAGE_BASE_URL,
          blockchainExplorer: {
            btcTestnet: window.INITIAL_STATE?.config?.BTC_TESTNET,
            ethMainnet: window.INITIAL_STATE?.config?.ETH_MAINNET,
            ethSepolia: window.INITIAL_STATE?.config?.ETH_TESTNET,
            ltcMainnet: window.INITIAL_STATE?.config?.LTC_MAINNET,
            xrpMainnet: window.INITIAL_STATE?.config?.XRP_MAINNET,
            trxMainnet: window.INITIAL_STATE?.config?.TRX_TRON_NET_ADDRESS,
            trxTestnet: window.INITIAL_STATE?.config?.TRX_TRON_NET_ADDRESS,
          }
        },
      }
      : {
        config: {
          apiUrl: "http://localhost:8080",
          imageBaseUrl: process.env.IMAGE_BASE_URL, //Please use dev url in local to use if this doesnt work
          blockchainExplorer: {
            btcMainnet: "https://www.blockchain.com/btc/address",
            btcTestnet: "https://www.blockchain.com/btc-testnet/address",
            ethMainnet: "https://etherscan.io/address",
            ethSepolia: "https://sepolia.etherscan.io/address",
            ltcMainnet: "https://blockchair.com/litecoin/address",
            xrpMainnet: "https://blockchair.com/ripple/account",
            trxMainnet: process.env.TRX_TRON_NET_ADDRESS,
            trxTestnet: process.env.TRX_TRON_NET_ADDRESS,
          }
        },
      };
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== "production",
  });

  return store;
};

export default configureAppStore;
