import React, { useState, useEffect } from "react";
import { Box, Button, MenuItem } from "@mui/material";
import CommonDatePicker from "../common/CommonDatePicker.js";
import apiRequest from "../../utils/api.js";
import FilterDropdown from "../common/FilterDropdown.js";
import { useSelector } from "react-redux";

const TransactionListFilters = (props) => {
  const [inputFields, setInputFields] = useState({
    type: [],
    applicationId: "",
    status: [],
    fromDate: "",
    toDate: "",
  });
  const [allApplications, setAllApplications] = useState([]);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  useEffect(() => {
    const getApplications = async () => {
      const res = await apiRequest("/get-all-application", "POST");
      if (res?.success) {
        setAllApplications(res.data);
      }
    };
    getApplications();
  }, []);

  const { setFilters, setPage, handleDrawer } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "type" || name === "status") {
      setInputFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onSubmit = () => {
    setPage(1);
    setFilters(inputFields);
    handleDrawer(false);
  };

  const reset = () => {
    setFilters({});
    setInputFields({
      type: [],
      application: "",
      status: [],
      fromDate: "",
      toDate: "",
    });
  };

  const transactionType = [
    { type: "Buy", key: "buy" },
    { type: "Sell", key: "sell" },
    { type: "Receive", key: "receive" },
    { type: "Send", key: "send" },
    { type: "Exchange", key: "exchange" },
  ];
  const transactionStatus = [
    { status: "Completed", key: "completed" },
    { status: "Proccessing", key: "processing" },
    { status: "Failed", key: "failed" },
  ];

  const setDateRange = (startDate, endDate) => {
    setInputFields((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
    }));
  };

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex gap-4">
        <FilterDropdown
          label="Type"
          value={inputFields.type}
          name="type"
          data={transactionType}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder="Type"
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {transactionType?.length > 0 &&
            transactionType.map((item, index) => {
              return (
                <MenuItem key={`${item?.type}${index}`} value={item?.key}>
                  {item?.type}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Application"
          value={inputFields?.applicationId || "Select an option"}
          name="applicationId"
          data={allApplications}
          handleInputChange={handleInputChange}
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {allApplications?.length > 0 &&
            allApplications.map((item, index) => {
              return (
                <MenuItem
                  key={`${item?._id}${index}`}
                  value={item?.applicationId}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Status"
          value={inputFields.status}
          name="status"
          data={transactionStatus}
          isMultiple={true}
          handleInputChange={handleInputChange}
          placeholder="Status"
        >
          <MenuItem value="Select an option" disabled>
            Select an option
          </MenuItem>
          {transactionStatus?.length > 0 &&
            transactionStatus.map((item, index) => {
              return (
                <MenuItem key={`${item?.status}${index}`} value={item?.key}>
                  {item?.status}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <Box>
          <p className="text-sm mb-2 font-normal text-[#51525C]">Date</p>
          <CommonDatePicker setDateRange={setDateRange} />
        </Box>
      </Box>
      <Box className="flex self-end gap-2">
        <Button size="large" variant="outlined" id="reset" onClick={reset}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
            alt="reload-icon"
            className="w-6 h-6 mr-2"
          />
          Reset
        </Button>
        <Button size="large" variant="contained" id="search" onClick={onSubmit}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
            alt="search-icon"
            className="w-6 h-6 mr-2"
          />
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default TransactionListFilters;
